// Column-sort-icon fix:

.ui-jqgrid-sortable {
	overflow: visible!important;
}

.ui-jqgrid .ui-jqgrid-htable .colmenu {
	// &:before {
		position: relative!important;
		left: 2px;
		top: 1px;
	// }
}

// Column Sortable-Icon fix:

.ui-jqgrid-sortable {
	.s-ico {
		position: relative;
    	left: 13px;
	}
}

.ui-menu {
	width: 250px;
}


ul.ui-search-menu li a:hover {
	padding: 2px .4em!important;
	margin: 0!important;
}