// styles by Alex Frais

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.dd3-item {
	> button {
		&.dd-collapse {
			display: block;
		}
		
		&.dd-expand {
			display: none;
		}
	}
	
	&.dd-collapsed > button {
		&.dd-collapse {
			display: none;
		}
		
		&.dd-expand {
			display: block;
		}
	}
}

.modal-content, .modal-body .FormGrid, #add-edit-dialog, .ui-dialog {
	overflow: visible !important;
}

.jarviswidget > div[role="content"] {
	box-sizing: border-box;
}

.smart-form .jarviswidget > div {
	width: 100%;
}

/*
.ui-jqgrid,
.ui-jqgrid-view.table-responsive,
.ui-jqgrid-hdiv,
.ui-jqgrid-hbox,
.ui-jqgrid-htable,
.ui-jqgrid-bdiv,
.ui-jqgrid-btable,
.ui-jqgrid-pager {
	min-width: 100% !important;
}
*/

.ui-jqgrid-hbox {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.ui-jqgrid, .ui-jqgrid-view.table-responsive {
	width: 100% !important;
}

.ui-jqgrid-pager {
	max-width: 100% !important;
}

#content > .row {
	@for $i from 1 through 12 {
		.col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, {
			> .row {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}

.ui-jqgrid-view {
	.ui-jqgrid-bdiv {
		overflow: v-scroll;
	}
}

#wid-id-assign .widget-body {
	overflow: auto;
}

.smart-form .jarviswidget {
	margin-bottom: 30px;
}

.ui-paging-info {
	white-space: pre-wrap;
}

#header {
	#q {
		width: 42.5vw;
		max-width: 800px;
	}
}

@media screen and (max-width: 1200px) {
	#header {
		#q {
			width: 36vw;
		}
	}
}

@media screen and (max-width: 990px) {
	#header {
		#q {
			width: 22vw;
		}
	}
	
	#page-footer {
		padding-left: 14px !important;
	}
}

/*
@media screen and (max-width: 990px) {
	#header {
		#q {
			width: 36vw;
		}
	}
}
*/

#header {
	background: #fff !important;
}

body.smart-style-4 {
	/* menu, logout, fullscreen */
	#hide-menu, #logout-form, #fullscreen {
		a {
			background-color: #f8f8f8 !important;
			background-image: -webkit-gradient(linear,left top,left bottom,from(#f8f8f8),to(#f1f1f1)) !important;
			background-image: -webkit-linear-gradient(top,#f8f8f8,#f1f1f1) !important;
			background-image: -moz-linear-gradient(top,#f8f8f8,#f1f1f1) !important;
			background-image: -ms-linear-gradient(top,#f8f8f8,#f1f1f1) !important;
			background-image: -o-linear-gradient(top,#f8f8f8,#f1f1f1) !important;
			background-image: linear-gradient(top,#f8f8f8,#f1f1f1) !important;
			border: 1px solid #bfbfbf !important;
			color: #6D6A69 !important;
			
			width: 30px !important;
			height: 30px !important;
			min-width: 30px !important;
			margin: 10px 0 0 !important;
			padding: 2px !important;
			line-height: 24px !important;
			font-size: 17px !important;
		}
	}
	
	#hide-menu i {
		font-size: 100% !important;
	}
	
	&.hidden-menu #hide-menu i {
		color: #6D6A69 !important;
	}
	
	/* Username */
	.btn-header.transparent > a {
		color: #337ab7 !important;
		line-height: 20px !important;
	}
}

@media screen and (max-width: 979px) {
	body.smart-style-4  #hide-menu.btn-header > span > a {
		border-color: #bfbfbf !important;
	}
}

/* Budgetlinien */

.dd3-item {
	.dd-collapse, .dd-expand {
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		height: 51px;
		margin: 0 0 0 34px;
		border-radius: 0;
		line-height: 52px !important;
		
		&:before {
			content: "\f078" !important;
			color: #818181 !important;
		}
	}
	
	.dd-collapse {
		&:before {
			content: "\f077" !important;
		}
	}
	
	.dd-collapse, .dd-expand {
		&:hover:before {
			color: #0091d9 !important;
		}
	}
}

.dd3-handle {
	width: 28px;
	height: 51px;
	padding: 17px 14px;
	
	&:before {
		top: 0;
		line-height: 50px;
	}
}

.dd3-content {
	@include clearfix();
	min-height: 51px;
	padding: 4px 101px 4px 41px;
	border-color: #aaa;
	
	&:hover {
		color: #333;
	}
	
	.item-name, .item-options, .item-org {
		display: block;
	}
	
	.item-name, .item-org {
		padding-top: 1px;
	}
	
	.item-name {
		font-size: 13px;
		font-weight: bold;
	}
	
	.type-total {
		.item-name {
			padding-top: 11px;
		}
	}
	
	.item-org {
		margin-right: 10px;
		padding: 0;
		font-size: 11px;
	}
	
	.item-options {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
	}
	
	&.type-out, &.type-in {
		.item-org, .item-name {
			color: #333;
		}
	}
	
	&.type-out {
		background: rgba(200, 0, 0, .13);
	}
	
	&.type-in {
		background: rgba(0, 100, 0, .13);
	}
	
	i {
		position: relative;
		width: 30px;
		height: 30px;
		margin: 0 !important;
		cursor: pointer;
		color: #818181;
		border: 1px solid #aaa;
		background: #bfbfbf;
		background: -webkit-linear-gradient(top,#ddd 0,#bbb 100%);
		background: -moz-linear-gradient(top,#ddd 0,#bbb 100%);
		background: linear-gradient(top,#ddd 0,#bbb 100%);
		
		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
		
		&:hover {
			color: #818181;
			
			&.add-item {
				color: rgba(51,122,183,1);
				border-color: rgba(51,122,183,1);
				background: #9dc8ed;
			
				&.fa-plus {
					color: rgba(113,160,106,1);
					border-color: rgba(113,160,106,1);
					background: #b9dab4;
				}
			}
			
			&.delete-item {
				color: rgba(207,91,91,1);
				border-color: rgba(207,91,91,1);
				background: #e9b8b8;
			}
		}
	}
}

.dd-expand + .dd-handle + .dd3-content {
	padding-left: 68px;
}

/* Tabellen Toolbar */

.ui-jqgrid {
	border-radius: 0 !important;
	
	.ui-jqgrid-toppager {
		height: 31px !important;
	}
	
	.ui-jqgrid-pager, .ui-jqgrid-toppager {
		padding: 0 !important;
		
		.ui-pager-table {
			padding: 0;
			
			tr td {
				padding: 0;
			}
		}
		
		//ui-paging-pager
		
		.ui-pg-button {
			&.ui-disabled {
				cursor: default;
				background: transparent !important;
			}
		}
		
		.ui-pg-button .ui-pg-div, .ui-paging-pager .ui-pg-button {
			position: relative;
			width: 30px;
			height: 30px;
			padding: 0;
			color: #818181;
			border: 1px solid rgba(170,170,170,1) !important;
			background: #bfbfbf;
			background: -webkit-linear-gradient(top,#ddd 0,#bbb 100%);
			background: -moz-linear-gradient(top,#ddd 0,#bbb 100%);
			background: linear-gradient(top,#ddd 0,#bbb 100%);
			
			> .glyphicon, > .fa {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				margin: 0;
			}
		}
		
		.ui-pg-button .ui-pg-div.ui-disabled, .ui-paging-pager .ui-pg-button.ui-disabled {
			border: 1px solid rgba(170,170,170,.35) !important;
			background: #bfbfbf !important;
			background: -webkit-linear-gradient(top,#ddd 0,#bbb 100%) !important;
			background: -moz-linear-gradient(top,#ddd 0,#bbb 100%) !important;
			background: linear-gradient(top,#ddd 0,#bbb 100%) !important;
		}
		
		.ui-pg-button {
			.ui-separator {
				margin: 0 3px;
				border: 0 !important;
			}
			
			&.active {
				&#add_project_index_grid,
				&#add_project_index_grid_top,
				&#add_projectphases_index_grid,
				&#add_projectphases_index_grid_top,
				&#add_subprojects_index_grid,
				&#add_subprojects_index_grid_top,
				&#add_organisation_index_grid,
				&#add_organisation_index_grid_top,
				&#add_currency_index_grid,
				&#add_currency_index_grid_top,
				&#add_country_index_grid,
				&#add_country_index_grid_top,
				&#add_budget_index_grid,
				&#add_budget_index_grid_top,
				&#add_users_index_grid,
				&#add_users_index_grid_top,
				&#add_roles_index_grid,
				&#add_roles_index_grid_top,
				&#add_system_message_index_grid,
				&#add_system_message_index_grid_top,
				&#add_language_index_grid,
				&#add_language_index_grid_top,
				&#add_transactions_index_grid,
				&#add_transactions_index_grid_top {
					.ui-pg-div {
						color: rgba(113,160,106,1);
						border-color: rgba(113,160,106,1) !important;
						background: #b9dab4;
					}
				}
				
				&#del_project_index_grid,
				&#del_project_index_grid_top,
				&#del_projectphases_index_grid,
				&#del_projectphases_index_grid_top,
				&#del_subprojects_index_grid,
				&#del_subprojects_index_grid_top,
				&#del_organisation_index_grid,
				&#del_organisation_index_grid_top,
				&#del_currency_index_grid,
				&#del_currency_index_grid_top,
				&#del_country_index_grid,
				&#del_country_index_grid_top,
				&#del_budget_index_grid,
				&#del_budget_index_grid_top,
				&#del_users_index_grid,
				&#del_users_index_grid_top,
				&#del_roles_index_grid,
				&#del_roles_index_grid_top,
				&#del_system_message_index_grid,
				&#del_system_message_index_grid_top,
				&#del_language_index_grid,
				&#del_language_index_grid_top,
				&#del_transactions_index_grid,
				&#del_transactions_index_grid_top {
					.ui-pg-div {
						color: rgba(207,91,91,1);
						border-color: rgba(207,91,91,1) !important;
						background: #e9b8b8;
					}
				}
			}
		}
		
		.ui-pg-button.active .ui-pg-div, .ui-paging-pager .ui-pg-button.active {
			color: rgba(51,122,183,1);
			border-color: rgba(51,122,183,1) !important;
			background: #9dc8ed;
		}
		
		.ui-paging-pager .ui-pg-button {
			&:nth-child(3), &:nth-child(5) {
				width: 10px;
				margin: 0;
				padding: 0;
				border: 0 !important;
				background: none !important;
			}
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1620px), screen and (max-width: 700px) {
	.ui-jqgrid .ui-jqgrid-pager,
	.ui-jqgrid .ui-jqgrid-toppager {
		.ui-pg-button .ui-separator {
			margin: 0;
		}
	}
	
	.ui-jqgrid {
		.ui-jqgrid-pager, .ui-jqgrid-toppager {
			.ui-paging-pager .ui-pg-button {
				&:nth-child(3), &:nth-child(5) {
					display: none;
				}
			}
		}
	}
	
	#input_projectphases_index_gridpager,
	#projectphases_index_gridpager_right,
	#input_subprojects_index_gridpager,
	#subprojects_index_gridpager_right {
		display: none;
	}
}

.ui-jqgrid-btable {
	tr[role="row"] {
		td[role="gridcell"]:first-child {
			position: relative;
			padding: 0;
			
			> [type="checkbox"] {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

table.table-bordered td, table.table-bordered th {
	&:last-child:not(.ui-search-clear) {
		border-right: 1px solid #ddd !important;
	}
}

/* Modals */

.ui-jqdialog {
	font-size: 13px;
}

.modal-header .close {
	position: absolute;
	top: 12px;
	right: 6px;
	width: 30px;
	height: 30px;
}

.ui-jqdialog.modal-content {
	width: 600px !important;
	padding: 0;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #999;
	border: 1px solid rgba(0,0,0,.2);
	border-radius: 6px;
	outline: 0;
	-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
	box-shadow: 0 3px 9px rgba(0,0,0,.5);
	
	.ui-jqdialog-titlebar-close {
		position: absolute;
		top: 12px;
		right: 3px;
		width: 30px;
		height: 30px;
		margin: 0;
		padding: 0;
		filter: alpha(opacity=20);
		opacity: .2;
		text-align: center;
		
		.glyphicon {
			display: inline;
			margin: 0;
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
			font-size: 21px;
			font-weight: 700;
			line-height: 1;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			
			&:before {
				content: "\00d7";
			}
		}
		
		&:hover {
			text-decoration: none;
			filter: alpha(opacity=50);
			opacity: .5;
		}
	}
	
	.ui-jqdialog-title {
		display: block;
		margin: 0;
		padding: 0;
		float: none !important;
		line-height: 1.42857143;
		font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
		font-weight: 300;
		font-size: 18px;
	}
	
	.ui-jqdialog-titlebar.modal-header {
		height: auto;
		padding: 15px;
		border-bottom: 1px solid #e5e5e5;
	}
	
	.ui-jqdialog-content.modal-body {
		padding: 0;
		
		.FormGrid {
			margin: 0;
		}
		
		> div > div, > div > form {
			padding: 15px 15px 0;
		}
		
		> .EditTable, > div > .EditTable {
			margin: 0;
			padding: 0;
			text-align: right;
			border-top: 1px solid #e5e5e5;
			
			tr > td > hr {
				display: none;
			}
			
			.EditButton {
				padding: 15px;
				
				a {
					float: right;
				}
				
				.btn.fm-button {
					height: auto;
					margin: 0 0 0 8px;
					padding: 6px 12px;
					
					&:hover {
						color: #333;
						background: #e6e6e6;
						border-color: #adadad;
					}
				}
				
				#sData {
					color: #fff;
					background-color: #337ab7;
					border-color: #2e6da4;
					
					&:hover {
						color: #fff;
						background-color: #286090;
						border-color: #204d74;
					}
				}
				
				#dData, #eData {
					.glyphicon {
						display: none;
					}
				}
				
				#dData {
					color: #cf5b5b;
					border-color: #cf5b5b;
					background-color: #f6d4d4;
					
					&:hover {
						color: #cf5b5b;
						border-color: #cf5b5b;
						background-color: #efc6c6;
					}
				}
			}
		}
	}
	
	#alertcnt_users_index_grid,
	#alertcnt_roles_index_grid,
	#alertcnt_system_message_index_grid,
	#alertcnt_translation_index_grid,
	#alertcnt_language_index_grid,
	#alertcnt_organisation_index_grid,
	#alertcnt_currency_index_grid,
	#alertcnt_country_index_grid,
	#alertcnt_budget_index_grid,
	#alertcnt_project_index_grid,
	#alertcnt_projectphases_index_grid,
	#alertcnt_subprojects_index_grid,
	#alertcnt_transactions_index_grid {
		> div {
			padding: 15px;
			font-size: 13px;
		}
	}
	
	.DelTable {
		.delmsg {
			padding: 15px 15px 0;
			font-size: 13px;
		}
	}
}

.ui-overlay, .ui-widget-overlay {
	opacity: .5 !important;
	background: rgb(0,0,0) !important;
}

@media screen and (max-width: 699px) {
	.ui-jqdialog.modal-content {
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		width: auto !important;
		margin: 10px;
	}
}

.jarviswidget-toggle-btn {
	.fa-minus:before {
		content: "\f077";
	}
	
	.fa-plus:before {
		content: "\f078";
	}
}

.ui-jqgrid .ui-jqgrid-sortable {
	padding-left: 15px;
	
	.colmenu {
		position: absolute !important;
		top: 6px;
		left: 0;
		right: auto !important;
	}
	
	.s-ico {
		left: 0;
	}
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

// preloader
.loading {
	position: relative;
	
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 30px;
		height: 30px;
		margin: 6px 0 0 -12px;
		border: 6px solid rgba(113, 160, 106, .5);
		border-bottom-color: rgba(113, 160, 106, 1);
		border-radius: 100%;
		background: transparent;
		visibility: visible;
		z-index: 1;
		animation: spin 2s infinite linear;
	}
}

/*
#budget_form {
	opacity: 1;
	transition: opacity .5s ease-in-out;
}
*/

#wid-budget.loading {
	&:before {
		margin-top: 36px;
	}
	
	.widget-body {
		min-height: 220px;
	}
	
	#budget_form {
		//opacity: 0;
		display: none;
	}
}





