@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */



#logo img {
    width: 120px !important;
}

#logo {
    margin-left: 0px !important;
    margin-top: 9px !important;
    color: #336699;
    font-size: 11px;
    width: 100%;
}

.ui-jqgrid .ui-jqgrid-htable .ui-th-div {
    height: auto !important;
}

.ui-jqgrid .ui-jqgrid-htable .colmenu {
    // position: unset !important;
    color: #636b6f !important;
}

.smart-style-4 #header > *:first-child {
    background: #fff none repeat scroll 0 0 !important;
}

.jarviswidget-color-green > header {
   background: #71a06a !important;
   border: 0 !important;
}

.smart-style-4:not(.mobile-view-activated) .login-info {
    display: block !important;
}

#q, #search {
    color: #666;
    margin: 10px 0 0 16px;
    width: 800px;
}

.ui-jqgrid {
    border: 0 !important;
}

.page-footer {
    padding: 8px 13px 0 233px;
}

.flash-message {
    margin-left: 0px;
    margin-right: 0px;
}

div.type-in span {
    color: #71a06a ;
}

div.type-out span {
    color: red;
}

div.type-sum span {
    color: rgb(51, 51, 51);
}


input.number,
td.number,
th.number {
    text-align: right;
}

.budget-table th {
    background-color: #f2f2f2;
}

.budget-table input {
    padding: 3px;
}

.dd {
    float: none;
}


.smart-form .jarviswidget header {
    margin: 0;
    padding: 0;
    color: #fff;
}

.smart-form #wid-id-2 {
    margin: 0 0 30px !important;
}

.smart-form .jarviswidget > div {
    width: 97%;
}


#reporting_table tr:last-child,
#reporting_table tr:nth-last-child(2),
#budget_table tr:last-child,
#budget_table tr:nth-last-child(2) {
    border-top: 2px solid #ddd;
}

#reporting_table td:first-child,
#reporting_table th:first-child,
#budget_table td:first-child,
#budget_table th:first-child {
    border-right-width: 2px;
}

td.positive {
    color: #71a06a;
}

td.negative {
    color: #f00;
}

input.cbox[type="checkbox"] {
    visibility: visible;
} 


.smart-style-4 #content > .row:first-child {
    background-color : transparent;
    margin: 0 -15px 0 -15px;
    border: 0;
}

.alert {
    margin-bottom: 10px !important;
}

.jarviswidget.nomargin {
    margin-bottom: 0;
}

// nestable2
.dd-expand {
  display: none;
}

.dd-collapsed .dd-collapse,
.dd-collapsed .dd-list {
  display: none;
}

.dd-collapsed .dd-expand {
  display: block;
}


.dd-empty {
    display: none !important;
}

.dd {
    max-width: none; 
}

.no-padding table {
    border-left: 0;
    border-right: 0;
    margin-bottom: 0;
}

.ui-autocomplete {
    cursor: pointer;
}

.font-lg,
h4.display-inline {
    font-size: 14px !important;
}

label.label {
    font-weight: bold !important;
}

.ui-jqgrid-btable tr[role="row"] td[role="gridcell"]:first-child > [type="checkbox"] {
    top: 16px !important;
}


td.total {
    font-weight: bold;
}

.footrow {
    font-size: 13px;
}


.jarviswidget-active > header {
   background: #ffa500 !important;
   border: 0 !important;
   color: #fff;
}

.jarviswidget-active > header > .jarviswidget-ctrls a {
    color: #fff !important;
}

.ui-jqgrid tr.ui-row-ltr td {
    font-size: 12px !important;
}

.ui-jqgrid tr.ui-row-ltr th {
    font-size: 12px !important;
}

.ui-autocomplete{
    max-height: 400px;
    overflow-y: auto;
    z-index: 99999;
}

.ui-jqgrid .ui-search-table .ui-search-oper,
.ui-jqgrid .ui-search-table .ui-search-clear
{
    display: none;
}

tr.total,
tr.total td {
    font-weight: bold;
    background: #D8D8D8;
}



.loading-small:before  {
    position: absolute;
    top: 4px;
    left: 50%;
    content: "";
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 4px solid rgba(113, 160, 106, .5);
    border-bottom-color: rgba(113, 160, 106, 1);
    border-radius: 100%;
    background: transparent;
    visibility: visible;
    animation: spin 2s infinite linear;
}

input.bulk {
    margin-right: 5px;
}

.ui-search-toolbar.active-filter th {
    background: #ffa500;
}

#column_menu > .ui-menu-item:nth-child(5), 
#column_menu > .ui-menu-item:nth-child(6),
#column_menu > .ui-menu-item:nth-child(9),
#column_menu > .ui-menu-item:nth-child(10) {
    display: none;
}


.closed {
    color: #ff9e9e;    
}

.archived {
    color: #ddd;
}

.examination {
    color: #ffc67c;
}

table .number,
div.readonlyfield {
    padding: 5px 10px;
    border: 1px solid #bdbdbd;
}

